



























































































































































import { Component, Vue } from "vue-property-decorator";
import LeaveRequestForm from "@/components/leave-request/LeaveRequestForm.vue";
import { LeaveReasonModule } from "@/store/modules/leave-reason-module";
import InfoComponent from "@/components/InfoComponent.vue";
import { LeaveRequestModule } from "@/store/modules/leave-request-module";
import { UserModule } from "@/store/modules/user-module";
import TableComponent from "@/components/TableComponent.vue";
import { DataTableHeader } from "vuetify";
import { LeaveReasonInterface, LeaveRequestInterface } from "@/types/leave-request.type";
import ConfirmationDialog from "@/components/utils/ConfirmationDialog.vue";
import { SnackbarModule } from "@/store/modules/snackbar-module";
import Dialog from "@/components/Dialog.vue";
import SaveButton from "@/components/buttons/SaveButton.vue";
import moment from "moment";
import { DataTableOption } from "@/types/data-table.type";

@Component({
  components: {
    LeaveRequestForm,
    InfoComponent,
    TableComponent,
    ConfirmationDialog,
    Dialog,
    SaveButton,
  },
})
export default class Requests extends Vue {
  private get leaveReasons(): LeaveReasonInterface[] {
    return LeaveReasonModule.leaveReasons;
  }

  private get dateRangeText() {
    return this.dateRange.join(" to ");
  }

  private get allLeaveCounts(): object {
    return LeaveRequestModule.allLeaveCounts;
  }

  private get headers(): DataTableHeader[] {
    return LeaveRequestModule.leaveRequestHeaders;
  }

  private get leaveRequests(): any {
    return LeaveRequestModule.allLeaveRequest;
  }

  get leaveRequestCount(): number {
    return LeaveRequestModule.leaveRequestCount;
  }

  public $refs!: {
    deleteDialog: HTMLFormElement;
    dialog: HTMLFormElement;
    leaveForm: HTMLFormElement;
  };

  private filter: any = {
    userId: UserModule.userProfile.realId,
    reason: -1,
    status: "All",
    startDate: Date.now(),
    endDate: new Date(new Date().setDate(new Date().getDate() + 30)),
  };

  private leaveRequestId: number | null | string = 1;

  private leaveRequest: any = {};

  private status: any = ["All", "Approved", "Pending", "Rejected"];

  private leaveReasonSelector: object = [
    { id: -1, name: "All" },
    { id: 1, name: "Personal Leave" },
    { id: 2, name: "Vacation Leave" },
    { id: 3, name: "Sick Leave" },
  ];

  private menu: boolean = false;

  private dateRange: string[] = [moment().format("YYYY-MM-DD"), moment().add(30, "days").format("YYYY-MM-DD")];

  private defaultLeaveRequest: LeaveRequestInterface = {
    reason: -1,
    startDate: "",
    endDate: "",
    notes: "",
    duration: "full-day",
  };

  private options?: DataTableOption;

  private getLimit(reasonId: number) {
    const res = UserModule.userProfile.leaveRequestLimit.filter((data: any) => data.reason.id === reasonId);
    return res[0].limit;
  }

  private async created() {
    await LeaveReasonModule.getLeaveReasons();
    await LeaveRequestModule.getAllLeaveCounts(UserModule.userProfile.realId);
    this.filter.startDate = this.dateRange[0];
    this.filter.endDate = this.dateRange[1];
    await LeaveRequestModule.filterLeaveRequest(this.filter);
  }

  private openLeaveRequestDeleteDialog(id: number) {
    this.leaveRequestId = id;
    this.$refs.deleteDialog.openDialog();
  }

  private async deleteLeaveRequest() {
    try {
      await LeaveRequestModule.deleteLeaveRequest(this.leaveRequestId as number);
      this.$refs.deleteDialog.closeDialog();
    } catch ({ response }) {
      await SnackbarModule.setSnack({
        color: "error",
        message: response.data.detail,
      });
      return;
    }
    await SnackbarModule.setSnack({
      color: "success",
      message: "Delete leave request successfully!",
    });
    await LeaveRequestModule.getAllLeaveCounts(UserModule.userProfile.realId);
  }

  private openEditLeaveRequestDialog(item: any) {
    this.leaveRequest = Object.assign({}, item);
    this.$refs.dialog.openDialog();
  }

  private async simpleCloseDialog() {
    this.$refs.dialog.simpleCloseDialog();
    await LeaveRequestModule.getAllLeaveCounts(UserModule.userProfile.realId);
  }

  private updateDateRange(newValue: string[]) {
    this.dateRange = newValue;
    this.dateRange.sort();
    if (this.dateRange.length === 2) {
      this.menu = false;
    }
  }

  private clickFilter() {
    this.filter.startDate = this.dateRange[0];
    this.filter.endDate = this.dateRange[1];
    this.filter.options = this.options;
    LeaveRequestModule.filterLeaveRequest(this.filter);
  }

  private optionChanged(newOptions: DataTableOption) {
    this.options = newOptions;
    this.clickFilter();
  }

  private isRemovableDate(lrStartDate: Date) {
    const currentDate = new Date();
    return lrStartDate >= currentDate;
  }
}
