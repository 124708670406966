





















































import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import SaveButton from "@/components/buttons/SaveButton.vue";
import Divider from "@/components/Divider.vue";

@Component({
  components: {
    Header,
    SaveButton,
    Divider,
  },
})
export default class InfoComponent extends Vue {
  @Prop({
    default: "bag-icon.svg",
    type: String,
  })
  private icon!: string;

  @Prop({
    default: "Personal Leave",
    type: String,
  })
  private name!: string;

  @Prop({
    default: 0,
    type: Number,
  })
  private count!: number;

  @Prop({
    default: 0,
    type: Number,
  })
  private limit!: number;

  @Prop({
    default: 0,
    type: Number,
  })
  private rejectedCount!: number;

  @Prop({
    default: 0,
    type: Number,
  })
  private pendingCount!: number;
}
