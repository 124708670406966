import { getModule, Module, MutationAction, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { LeaveReasonInterface } from "@/types/leave-request.type";
import { getLeaveReasons } from "@/services/leave-request.service";

@Module({
  dynamic: true,
  name: "leave reason",
  store,
})
class LeaveReason extends VuexModule {
  public leaveReasons: LeaveReasonInterface[] = [];

  @MutationAction({ mutate: ["leaveReasons"], rawError: true })
  public async getLeaveReasons() {
    const leaveReasons: LeaveReasonInterface[] = await getLeaveReasons();
    return { leaveReasons };
  }
}

export const LeaveReasonModule = getModule(LeaveReason);
