
















































































































import { Component, Ref, Vue, Prop, Watch } from "vue-property-decorator";
import { VuetifyForm } from "@/types/vuetify.type";
import moment from "moment";
import { SnackbarModule } from "@/store/modules/snackbar-module";
import Header from "@/components/Header.vue";
import SaveButton from "@/components/buttons/SaveButton.vue";
import { LeaveReasonModule } from "@/store/modules/leave-reason-module";
import { LeaveReasonInterface, LeaveRequestInterface } from "@/types/leave-request.type";
import { FullDetailUserInterface } from "@/types/user.type";
import { UserModule } from "@/store/modules/user-module";
import { LeaveRequestModule } from "@/store/modules/leave-request-module";
import CloseButton from "@/components/buttons/CloseButton.vue";

@Component({
  components: {
    Header,
    SaveButton,
    CloseButton,
  },
})
export default class LeaveRequestForm extends Vue {
  private get dateRangeText() {
    return this.dateRange.join(" to ");
  }

  get userProfile(): FullDetailUserInterface {
    return UserModule.userProfile;
  }

  private get leaveReasons(): LeaveReasonInterface[] {
    return LeaveReasonModule.leaveReasons;
  }

  private get durationSelection() {
    return [
      {
        text: "Full-Day",
        value: "full-day",
      },
      {
        text: "Half-Day Morning",
        value: "half-morning",
      },
      {
        text: "Half-Day Afternoon",
        value: "half-afternoon",
      },
    ];
  }

  public $refs!: {
    form: HTMLFormElement;
  };

  @Prop({
    default: false,
    type: Boolean,
  })
  private readonly editMode!: boolean;

  @Prop({
    type: Function,
  })
  private simpleClose!: () => void;

  @Prop({
    default() {
      return {};
    },
    type: Object,
  })
  private readonly leaveRequest!: any;

  private commentRule = [
    (v: any) => {
      return this.isOptionalComment || !!(v && v.trim()) || "This field is required";
    },
  ];

  private reasonRule = [(v: any) => (!!v && v !== -1) || "This field is required"];

  private menu: boolean = false;
  private dateRange: string[] = [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")];

  private dateRules = [(v: any) => (!!v && v.includes("to")) || "You must select Start date and End date"];

  private defaultLeaveRequest: LeaveRequestInterface = {
    reason: -1,
    startDate: "",
    endDate: "",
    notes: "",
    duration: "full-day",
  };

  get leaveRequestDayRange() {
    return moment(this.dateRange[1]).diff(moment(this.dateRange[0]), "days");
  }

  get isOptionalComment() {
    const matchedLeaveReason = this.leaveReasons.find((lr) => lr.id === this.leaveRequest.reason);
    return !!matchedLeaveReason?.optionalComment;
  }

  private valid: boolean = false;

  @Ref()
  private form!: VuetifyForm;

  public setDateRange(): void {
    if (this.editMode) {
      this.dateRange = [this.leaveRequest.startDate, this.leaveRequest.endDate];
    }
  }

  private async created() {
    this.setDateRange();
  }

  private updateDateRange(newValue: string[]) {
    this.dateRange = newValue;
    this.dateRange.sort();
    if (this.dateRange.length === 2) {
      this.menu = false;
    }
  }

  private async createRequest() {
    this.form.validate();
    if (!this.form.validate()) {
      await SnackbarModule.setSnack({
        color: "error",
        message: " Please fill in all the fields",
      });
      return;
    }
    try {
      this.leaveRequest.startDate = this.dateRange[0];
      this.leaveRequest.endDate = this.dateRange[1];
      this.leaveRequest.notes = this.leaveRequest.notes ? this.leaveRequest.notes.trim() : "";
      if (this.editMode) {
        await LeaveRequestModule.editLeaveRequest(this.leaveRequest);
      } else {
        await LeaveRequestModule.addLeaveRequest(this.leaveRequest);
      }
    } catch ({ response }) {
      await SnackbarModule.setSnack({
        color: "error",
        message: response.data.detail,
      });
      return;
    }
    if (!this.editMode) {
      this.$refs.form.reset();
    }
    this.form.resetValidation();
    await SnackbarModule.setSnack({
      color: "success",
      message: "Create request successfully!",
    });
    this.simpleClose();
  }

  @Watch("leaveRequest")
  private onLeaveRequestChange(val: any, oldVal: any) {
    this.setDateRange();
  }

  @Watch("leaveRequestDayRange")
  private onLeaveRequestDayRangeChanges(val: any) {
    if (val > 0) {
      this.leaveRequest.duration = "full-day";
    }
  }
}
