import { Action, getModule, Module, Mutation, MutationAction, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import {
  LeaveRequestAllInterface,
  LeaveRequestApprovalInterface,
  LeaveRequestHistoryInterface,
  LeaveRequestHistoryPaginationInterface,
  LeaveRequestInterface,
  LeaveRequestPaginationInterface,
} from "@/types/leave-request.type";
import {
  addLeaveRequest,
  deleteLeaveRequests,
  editLeaveRequest,
  filterLeaveRequests,
  getAllLeaveCount,
  getAllLeaveRequests,
  getHistoryLeaveRequestService,
  getLeaveCount,
  getLeaveRequest,
  getLeaveRequestByMonth,
  getPendingLeaveRequestService,
} from "@/services/leave-request.service";
import { UserModule } from "@/store/modules/user-module";
import { DataTableHeader } from "vuetify";
import { DataTableOption } from "@/types/data-table.type";

@Module({
  dynamic: true,
  name: "leave request",
  store,
})
class LeaveRequest extends VuexModule {
  public static async getCount(createdById: number, reasonId: number) {
    const leave: any = await getLeaveCount(createdById, reasonId);
    return leave.length;
  }

  public pendingLeaveRequest: LeaveRequestApprovalInterface[] = [];
  public historyLeaveRequest: LeaveRequestHistoryInterface[] = [];
  public allLeaveRequest: LeaveRequestInterface[] = [];
  public allPublicLeaveRequest: LeaveRequestAllInterface[] = [];
  public leaveRequest: LeaveRequestInterface = {} as LeaveRequestInterface;
  public personalLeaveCount: number = 0;
  public vacationLeaveCount: number = 0;
  public sickLeaveCount: number = 0;
  public leaveRequestCount: number = 0;
  public pendingLeaveRequestCount: number = 0;
  public historyLeaveRequestCount: number = 0;
  public allLeaveCounts: {} = {};
  public leaveRequestHeaders: DataTableHeader[] = [
    {
      text: "Date",
      value: "startDate",
    },
    {
      text: "Duration",
      value: "duration",
    },
    {
      text: "Reason",
      value: "reasonName",
    },
    {
      text: "Notes / Comment",
      value: "notes",
    },
    {
      text: "Approve",
      value: "approved",
    },
    {
      text: "Approve By",
      value: "approvedByInfo",
    },
    {
      text: "Action",
      value: "action",
    },
  ];

  @Action({ rawError: true })
  public async getAllLeaveCounts(userId: number) {
    const currentYear = new Date().getFullYear();
    const allLeaveCounts = await getAllLeaveCount(userId, currentYear);
    this.SET_ALL_LEAVE_COUNTS(allLeaveCounts);
  }

  @Action({ rawError: true })
  public async addLeaveRequest(request: LeaveRequestInterface) {
    await addLeaveRequest(request);
    await this.getAllLeaveRequest(UserModule.userProfile.realId);
  }

  @Action({ rawError: true })
  public async editLeaveRequest(request: LeaveRequestInterface) {
    await editLeaveRequest(request);
    await this.getAllLeaveRequest(UserModule.userProfile.realId);
  }

  @Action({ rawError: true })
  public async getLeaveRequest(id: number) {
    const leaveRequest = await getLeaveRequest(id);
    this.SET_ACTIVE_LEAVE_REQUEST(leaveRequest);
  }

  @Action({ rawError: true })
  public async getAllLeaveRequest(id: number) {
    const allLeaveRequests = await getAllLeaveRequests(id);
    this.SET_ALL_LEAVE_REQUESTS(allLeaveRequests);
  }

  @Action({ rawError: true })
  public async filterLeaveRequest(filter: any) {
    const allLeaveRequests = await filterLeaveRequests(
      filter.userId,
      filter.reason,
      filter.status,
      filter.startDate,
      filter.endDate
    );
    this.SET_ALL_LEAVE_REQUESTS(allLeaveRequests);
  }

  @Action({ rawError: true })
  public async getLeaveCount(createdBy: number) {
    const personalLeaveCount = await LeaveRequest.getCount(createdBy, 1);
    const vacationLeaveCount = await LeaveRequest.getCount(createdBy, 2);
    const sickLeaveCount = await LeaveRequest.getCount(createdBy, 3);
    this.SET_PERSONAL_COUNT(personalLeaveCount);
    this.SET_VACATION_COUNT(vacationLeaveCount);
    this.SET_SICK_COUNT(sickLeaveCount);
  }

  @Action({ rawError: true })
  public async getPendingLeaveRequest(options?: DataTableOption) {
    const pendingLeaveRequestList = await getPendingLeaveRequestService(options);
    this.SET_PENDING_LEAVE_REQUEST(pendingLeaveRequestList);
  }

  @Action({ rawError: true })
  public async getHistoryLeaveRequest(options?: DataTableOption) {
    const historyLeaveRequest = await getHistoryLeaveRequestService(options);
    this.SET_HISTORY_LEAVE_REQUEST(historyLeaveRequest);
  }

  @Action({ rawError: true })
  public async deleteLeaveRequest(id: number) {
    await deleteLeaveRequests(id);
    await this.getAllLeaveRequest(UserModule.userProfile.realId);
  }

  @MutationAction({ mutate: ["allPublicLeaveRequest"] })
  public async getAllLeaveRequestByMonth(filter: any) {
    const allPublicLeaveRequest: LeaveRequestAllInterface[] = await getLeaveRequestByMonth(
      filter.startDate,
      filter.endDate
    );
    return { allPublicLeaveRequest };
  }

  @Mutation
  private SET_ALL_LEAVE_COUNTS(countObj: any) {
    this.allLeaveCounts = countObj;
  }

  @Mutation
  private SET_PERSONAL_COUNT(count: number) {
    this.personalLeaveCount = count;
  }

  @Mutation
  private SET_VACATION_COUNT(count: number) {
    this.vacationLeaveCount = count;
  }

  @Mutation
  private SET_SICK_COUNT(count: number) {
    this.sickLeaveCount = count;
  }

  @Mutation
  private SET_PENDING_LEAVE_REQUEST(leaveRequests: LeaveRequestPaginationInterface) {
    const results: LeaveRequestApprovalInterface[] = [];
    for (const leave of leaveRequests.results) {
      results.push({
        id: leave.id!,
        date: `${leave.startDate} to ${leave.endDate}`,
        user: leave.createdByInfo?.userDisplay!,
        reason: leave.reasonName!,
        notes: leave.notes,
        duration: leave.duration,
      });
    }
    this.pendingLeaveRequestCount = leaveRequests.count;
    this.pendingLeaveRequest = results;
  }

  @Mutation
  private SET_HISTORY_LEAVE_REQUEST(leaveRequests: LeaveRequestHistoryPaginationInterface) {
    const results: LeaveRequestHistoryInterface[] = [];
    for (const leave of leaveRequests.results) {
      results.push({
        id: leave.id!,
        date: `${leave.startDate} to ${leave.endDate}`,
        user: leave.createdByInfo?.userDisplay!,
        reason: leave.reasonName!,
        notes: leave.notes,
        approved: leave.approved!,
        approved_by: leave.approvedByInfo!.userDisplay,
        duration: leave.duration,
      });
    }
    this.historyLeaveRequestCount = leaveRequests.count;
    this.historyLeaveRequest = results;
  }

  @Mutation
  private SET_ACTIVE_LEAVE_REQUEST(leaveRequest: LeaveRequestInterface) {
    this.leaveRequest = leaveRequest;
  }

  @Mutation
  private SET_ALL_LEAVE_REQUESTS(leaveRequests: LeaveRequestPaginationInterface) {
    this.allLeaveRequest = leaveRequests.results;
    this.leaveRequestCount = leaveRequests.count;
  }
}

export const LeaveRequestModule = getModule(LeaveRequest);
